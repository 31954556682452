import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { TranslocoModule } from '@ngneat/transloco';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { SwiperModule } from 'swiper/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { ErrorListComponent } from './components/form-field-error/error-list/error-list.component';
import { TextComponent } from './components/text/text.component';
import { PasswordStrengthIndicatorComponent } from './components/password-strength-indicator/password-strength-indicator.component';
import { OrderStatusComponent } from './components/order/order-status/order-status.component';
import { MenuStripComponent } from './components/layout/menu-strip/menu-strip.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { BasicLayoutComponent } from './components/layout/basic-layout/basic-layout.component';
import { IconComponent } from './components/icon/icon.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ContainerComponent } from './components/layout/container/container.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { ImageComponent } from './components/image/image.component';
import { InViewDirective } from './directives/in-view.directive';
import { SearchComponent } from './components/search/search.component';
import { PaperComponent } from './components/layout/paper/paper.component';
import { PaperHeaderComponent } from './components/layout/paper/paper-header/paper-header.component';
import { PartnersComponent } from './components/partners/partners.component';
import { PromoBlockComponent } from './components/promo-block/promo-block.component';
import { PromoTextComponent } from './components/promo-block/promo-text/promo-text.component';
import { BannerBlockComponent } from './components/banner-block/banner-block.component';
import { CatalogNavigationComponent } from './components/catalog-navigation/catalog-navigation.component';
import { ProductCardComponent } from './components/product/product-card/product-card.component';
import { ProductLabelComponent } from './components/product/product-label/product-label.component';
import { ProductGridComponent } from './components/product/product-grid/product-grid.component';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SmallCartComponent } from './components/cart/small-cart/small-cart.component';
import { PriceComponent } from './components/price/price.component';
import { QuantityComponent } from './components/quantity/quantity.component';
import { PasswordComponent } from './components/password/password.component';
import { AmountPipe } from './pipes/amount/amount.pipe';
import { SelectionMenuComponent } from './components/selection-menu/selection-menu.component';
import { ProductListItemComponent } from './components/product/product-list-item/product-list-item.component';
import { ProductListComponent } from './components/product/product-list/product-list.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ImageDialogComponent } from './components/gallery/image-dialog/image-dialog.component';
import { CatalogNavigationMenuComponent } from './components/layout/catalog-navigation-menu/catalog-navigation-menu.component';
import { BreadcrumbsComponent } from './components/layout/breadcrumbs/breadcrumbs.component';
import { SliderComponent } from './components/slider/slider.component';
import { ProductsComponent } from './components/product/products/products.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { CompareButtonComponent } from './components/compare-button/compare-button.component';
import { MyAccountLayoutComponent } from './components/layout/my-account-layout/my-account-layout.component';
import { ContinueShoppingConfirmationDialogComponent } from './components/continue-shopping-confirmation-dialog/continue-shopping-confirmation-dialog.component';
import { InitialsPipe } from './pipes/initials/initials.pipe';
import { StatusBlockComponent } from './components/status-block/status-block.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { FacetsComponent } from './components/facets/facets.component';
import { FacetSelectComponent } from './components/facets/facet-select/facet-select.component';
import { FacetRangeComponent } from './components/facets/facet-range/facet-range.component';
import { PriceSummaryComponent } from './components/price-summary/price-summary.component';
import { PasswordChangeDialogComponent } from './components/password-change-dialog/password-change-dialog.component';
import { DeliveryAddressAddDialogComponent } from './components/delivery-address-add-dialog/delivery-address-add-dialog.component';
import { ErrorComponent } from './components/error/error.component';
import { AlertSnackBarComponent } from './components/alert-snack-bar/alert-snack-bar.component';
import { DeleteAddressConfirmationDialogComponent } from './components/delete-address-confirmation-dialog/delete-address-confirmation-dialog.component';
import { DeliveryAddressComponent } from './components/delivery-address/delivery-address.component';
import { InvoiceAddressComponent } from './components/invoice-address/invoice-address.component';
import { OrderItemComponent } from './components/order/order-item/order-item.component';
import { FileDownloadComponent } from './components/file-download/file-download.component';
import { SectionComponent } from './components/layout/section/section.component';
import { StockComponent } from './components/stock/stock.component';
import { FormLayoutComponent } from './components/layout/form-layout/form-layout.component';
import { CardComponent } from './components/layout/card/card.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { AddressesListComponent } from './components/addresses-list/addresses-list.component';
import { AddressInfoComponent } from './components/address-info/address-info.component';
import { PayButtonComponent } from './components/pay-button/pay-button.component';
import { FormSubmittedDirective } from './directives/form-submitted.directive';
import { OrderProductImagesComponent } from './components/order/order-product-images/order-product-images.component';
import { DateRangePickerComponent } from './components/datepicker/date-range-picker.component';
import { AddToCartFromOrderConfirmationDialogComponent } from './components/order/add-to-cart-from-order-confirmation-dialog/add-to-cart-from-order-confirmation-dialog.component';
import { DateInputComponent } from './components/datepicker/date-input/date-input.component';
import { OrderStatusPipe } from './pipes/order-status/order-status.pipe';
import { LoadingDirective } from './directives/loading.directive';
import { AnchorOffsetComponent } from './components/anchor-offset/anchor-offset.component';
import { SmallDecimalsPipe } from './pipes/small-decimals/small-decimals.pipe';
import { customDateAdapterProvider } from './providers/custom-date-adapter-provider';
import { TabContainerComponent } from './components/layout/tab-container/tab-container.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PickupSiteDetailComponent } from './components/pickup-site-detail/pickup-site-detail.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { ActiveFacetsComponent } from './components/facets/active-facets/active-facets.component';
import { YoutubeVideoComponent } from './components/video/youtube-video/youtube-video.component';
import { CustomVideoComponent } from './components/video/custom-video/custom-video.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { PacketaWidgetComponent } from './components/packeta-widget/packeta-widget.component';
import { FloatingBlockComponent } from './components/layout/floating-block/floating-block.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { RafCurrencyPipe } from './pipes/currency/rafCurrency.pipe';
import { PricePipe } from './pipes/price/price.pipe';
import { SiteStockDialogComponent } from './components/site-stock-dialog/site-stock-dialog.component';
import { AtpFilterPipe } from './pipes/atp-filter/atp-filter.pipe';
import { ConditionalLinkComponent } from './components/conditional-link/conditional-link.component';
import { HeurekaWidgetComponent } from './components/heureka-widget/heureka-widget.component';
import { ThreeSixtyImageComponent } from './components/three-sixty-image/three-sixty-image.component';

@NgModule({
  declarations: [
    InputComponent,
    SelectComponent,
    FormFieldErrorComponent,
    ErrorListComponent,
    TextComponent,
    IconComponent,
    PasswordStrengthIndicatorComponent,
    OrderStatusComponent,
    MenuStripComponent,
    LoginDialogComponent,
    BasicLayoutComponent,
    FooterComponent,
    ContainerComponent,
    SectionComponent,
    LoadingComponent,
    HeaderComponent,
    ImageComponent,
    PaginationComponent,
    SearchComponent,
    PaperComponent,
    PaperHeaderComponent,
    PartnersComponent,
    PromoBlockComponent,
    PromoTextComponent,
    BannerBlockComponent,
    CatalogNavigationComponent,
    ProductCardComponent,
    ProductLabelComponent,
    ProductGridComponent,
    ContactDetailsComponent,
    SmallCartComponent,
    PriceComponent,
    QuantityComponent,
    PasswordComponent,
    SelectionMenuComponent,
    ProductListItemComponent,
    ProductListComponent,
    GalleryComponent,
    ImageDialogComponent,
    CatalogNavigationMenuComponent,
    BreadcrumbsComponent,
    SliderComponent,
    ProductsComponent,
    CategoryListComponent,
    CompareButtonComponent,
    MyAccountLayoutComponent,
    ContinueShoppingConfirmationDialogComponent,
    StatusBlockComponent,
    LogoutDialogComponent,
    FacetsComponent,
    FacetSelectComponent,
    FacetRangeComponent,
    PriceSummaryComponent,
    PasswordChangeDialogComponent,
    DeliveryAddressAddDialogComponent,
    ErrorComponent,
    AlertSnackBarComponent,
    FileDownloadComponent,
    DeleteAddressConfirmationDialogComponent,
    DeliveryAddressComponent,
    InvoiceAddressComponent,
    StockComponent,
    OrderItemComponent,
    FormLayoutComponent,
    CardComponent,
    OrderProductImagesComponent,
    DateRangePickerComponent,
    MobileMenuComponent,
    FileDownloadComponent,
    AddressesListComponent,
    AddressInfoComponent,
    PayButtonComponent,
    DateInputComponent,
    AddToCartFromOrderConfirmationDialogComponent,
    AnchorOffsetComponent,
    GoogleMapComponent,
    ConditionalLinkComponent,

    InViewDirective,
    FormSubmittedDirective,
    LoadingDirective,

    AmountPipe,
    RafCurrencyPipe,
    PricePipe,
    InitialsPipe,
    OrderStatusPipe,
    SmallDecimalsPipe,
    TabContainerComponent,
    NoDataComponent,
    PickupSiteDetailComponent,
    AutocompleteComponent,
    ActiveFacetsComponent,
    YoutubeVideoComponent,
    CustomVideoComponent,
    PacketaWidgetComponent,
    FloatingBlockComponent,
    SiteStockDialogComponent,
    AtpFilterPipe,
    HeurekaWidgetComponent,
    ThreeSixtyImageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatSliderModule,
    MatRadioModule,
    MatRippleModule,
    MatCheckboxModule,
    MatListModule,
    SatPopoverModule,
    SwiperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    GoogleMapsModule,
    MatAutocompleteModule,
    YouTubePlayerModule,
    MatTooltipModule,
  ],

  providers: [customDateAdapterProvider, AmountPipe, RafCurrencyPipe, AsyncPipe, AtpFilterPipe],

  exports: [
    InputComponent,
    SelectComponent,
    FormFieldErrorComponent,
    ErrorListComponent,
    TextComponent,
    IconComponent,
    PaperComponent,
    PaperHeaderComponent,
    PasswordStrengthIndicatorComponent,
    BasicLayoutComponent,
    OrderStatusComponent,
    FooterComponent,
    ContainerComponent,
    SectionComponent,
    PartnersComponent,
    PromoBlockComponent,
    BannerBlockComponent,
    CatalogNavigationComponent,
    PaginationComponent,
    ProductCardComponent,
    ProductListComponent,
    ProductListItemComponent,
    ProductGridComponent,
    HeaderComponent,
    PriceComponent,
    ContactDetailsComponent,
    QuantityComponent,
    PasswordComponent,
    SelectionMenuComponent,
    LoginDialogComponent,
    GalleryComponent,
    ImageDialogComponent,
    BreadcrumbsComponent,
    MenuStripComponent,
    CatalogNavigationMenuComponent,
    LoadingComponent,
    SliderComponent,
    ProductsComponent,
    CategoryListComponent,
    CompareButtonComponent,
    ImageComponent,
    ContinueShoppingConfirmationDialogComponent,
    StatusBlockComponent,
    FacetsComponent,
    DeliveryAddressAddDialogComponent,
    PriceSummaryComponent,
    ErrorComponent,
    SearchComponent,
    DeliveryAddressComponent,
    InvoiceAddressComponent,
    SearchComponent,
    AlertSnackBarComponent,
    OrderItemComponent,
    FileDownloadComponent,
    StockComponent,
    FormLayoutComponent,
    CardComponent,
    MobileMenuComponent,
    DateRangePickerComponent,
    OrderProductImagesComponent,
    AddressesListComponent,
    AddressInfoComponent,
    FileDownloadComponent,
    PayButtonComponent,
    AnchorOffsetComponent,
    SmallCartComponent,
    NoDataComponent,
    SiteStockDialogComponent,
    ConditionalLinkComponent,

    InViewDirective,
    FormSubmittedDirective,
    LoadingDirective,

    AmountPipe,
    RafCurrencyPipe,
    PricePipe,
    InitialsPipe,
    SmallDecimalsPipe,
    SmallCartComponent,
    TabContainerComponent,
    GoogleMapComponent,
    PickupSiteDetailComponent,
    ActiveFacetsComponent,
    YoutubeVideoComponent,
    CustomVideoComponent,
    FloatingBlockComponent,
    PacketaWidgetComponent,
    AtpFilterPipe,
    HeurekaWidgetComponent,
  ],
})
export class SharedModule {}
