import { Injectable, isDevMode } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';

import { environment } from '../../../environments/environment';
import { AppConfig } from '../../shared/model/app-config.model';
import { Logger } from './logger.service';
import { UserService } from './user.service';

@Injectable()
export class SentryService {
  constructor(private logger: Logger,
              private userService: UserService) {

  }

  initSentry(appConfig: AppConfig): void {
    this.logger.log(`[SentryErrorHandler] Initializing sentry with env=${appConfig.sentry.environment}, \
release=${environment.sentryRelease}`);
    Sentry.init({
      dsn: environment.sentryDns,
      environment: appConfig.sentry.environment,
      release: environment.sentryRelease,
      enabled: !isDevMode(),
      autoSessionTracking: appConfig.sentry.autoSessionTracking,
      sendClientReports: appConfig.sentry.sendClientReports,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: appConfig.sentry.tracesSampleRate || 1.0,
      ignoreErrors: [
        'Non-Error exception captured'
      ]
    });

    this.userService.user$.subscribe((user) => {
      this.logger.log(`[SentryErrorHandler] Setting user to sentry: ${user ? user.email : 'null'}`);
      Sentry.setUser(user ? {email: user.email} : null);
    })

  }
}
